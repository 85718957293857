<template>
  <p v-if="!emailOnly" v-text="t(museumType === 'location' ? 'selectLanguageAndEnterEmail' : 'selectYourLanguageMostInteractive')" />
  <div class="data-form flex-column">
    <div
      v-if="!emailOnly"
      data-test="language-field"
      class="field"
      @click="focusOn(languageRef)"
    >
      <label v-text="t('caSelectLanguage')" />
      <select ref="languageRef" v-model="userData.language" aria-label="Language">
        <option
          v-for="language of selectLanguages"
          :key="language.value"
          :value="language.value"
        >
          {{ language.text }}
        </option>
      </select>
    </div>
    <template v-if="museumType === 'location'">
      <div
        v-if="!emailOnly"
        data-test="tag-field"
        class="field"
        @click="focusOn(tagRef)"
      >
        <label v-text="t('enterTagNumber')" />
        <input
          ref="tagRef"
          v-model="userData.tag"
          aria-label="Tag"
          :disabled="disableTagInput"
        >
      </div>
      <div
        data-test="email-field"
        data-sentry-mask
        class="field"
        @click="focusOn(emailRef)"
      >
        <label v-text="t('enterEmail')" />
        <input
          ref="emailRef"
          v-model="userData.email"
          aria-label="Email"
          type="email"
        >
      </div>
    </template>
  </div>
  <button
    data-test="continue-btn"
    class="btn"
    :disabled="!formValid"
    @click="onContinue"
    v-text="t('saveAndContinue')"
  />
</template>

<script lang="ts">
import { computed, defineComponent, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { useDataStore } from '@/stores/data'
import { useLocalStorageStore } from '@/stores/local-storage'

export default defineComponent({
  emits: {
    continue: (): true => true,
  },

  props: {
    emailOnly: { type: Boolean, default: false },
  },

  setup (_props, { emit }) {
    const { t } = useI18n()
    const route = useRoute()
    const localStorageStore = useLocalStorageStore()
    const previousData = localStorageStore.getUserData()
    const queryTag = typeof route.query.tag === 'string' ? route.query.tag : null
    const userData = reactive({
      language: previousData?.language ?? 'en',
      tag: queryTag ?? previousData?.tag ?? '',
      email: previousData?.email ?? '',
    })
    // refresh email when updated for souvenirs
    watch(() => localStorageStore.getUserData()?.email, (val) => {
      userData.email = val ?? ''
    })

    const dataStore = useDataStore()
    const museumType = computed(() => dataStore.museumType)

    const selectedLanguage = ref(previousData?.language ?? 'en')
    watch(() => localStorageStore.getUserData()?.language, (val) => {
      selectedLanguage.value = val ?? 'en'
    })
    const selectLanguages = computed(() => {
      const languages = dataStore.languages.find((l) => l.symbol === selectedLanguage.value)?.languages ?? dataStore.languages
      return languages.map((l) => ({
        text: l.symbol === selectedLanguage.value ? l.display : `${l.display} (${l.native})`,
        value: l.symbol ?? '',
      }))
    })

    const formValid = computed(() => {
      if (!userData.language) return false
      if (museumType.value === 'location') {
        if (!userData.tag) return false
      }
      return true
    })

    function onContinue (): void {
      if (!formValid.value) return
      localStorageStore.setUserData({
        language: userData.language,
        email: userData.email,
        tag: userData.tag,
      })
      emit('continue')
    }

    const languageRef = ref<HTMLSelectElement | null>(null)
    const tagRef = ref<HTMLInputElement | null>(null)
    const emailRef = ref<HTMLInputElement | null>(null)
    function focusOn (el: HTMLSelectElement | HTMLInputElement | null): void {
      if (!el) return
      el.focus()
    }

    const disableTagInput = computed(() => {
      return !!queryTag
    })

    return {
      t,

      userData,
      museumType,
      selectLanguages,
      formValid,
      onContinue,

      languageRef,
      tagRef,
      emailRef,
      focusOn,

      disableTagInput,
    }
  },
})
</script>

<style lang="scss" scoped>
.data-form {
  margin: 4px 0 40px 0;
}
</style>
